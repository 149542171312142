.support-chat-window {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: transparent;
    color: inherit; /* Ensure text color is inherited */
}

.chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #313831;
    border-bottom: 1px solid #ccc;
    border-radius: 20px;
    color: #faeacc;
    position: sticky; /* Makes the header sticky */
    top: 0; /* Stick to the top */
    z-index: 10; /* Ensures the header stays above the content */
    flex-shrink: 0;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: transparent;
}

.message-row {
    display: flex;
    margin-bottom: 15px;
}

.message-row.sent {
    justify-content: flex-end;
    margin-bottom: 15px;
}

.message-row.received {
    justify-content: flex-start;
    margin-bottom: 15px;
}

.message-bubble {
    max-width: 70%;
    padding: 12px 16px;
    border-radius: 20px;
    position: relative;
    word-wrap: break-word;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; /* Add space for the timestamp */
}

.message-bubble.sent {
    background-color: #ea191c;
    color: #ffffff;
    border-top-right-radius: 0;
}

.message-bubble.received {
    background-color: #F3EEE0;
    color: #313831;
    border-top-left-radius: 0;
}

.message-bubble p {
    margin: 0;
    font-size: 14px;
}



.message-bubble img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

.chat-input {
    display: flex;
    align-items: center;
    background-color: #313831;
    border-radius: 30px; /* Rounded corners for the input field */
    padding: 10px; /* Padding inside the input field */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border: 1px solid #e0e0e0; /* Light border around the field */
    margin: 15px; /* Add some spacing from the bottom */
    position: sticky;
    bottom: 0;
}

.chat-input .MuiTextField-root {
    flex-grow: 1;
    margin: 0 10px;
    background-color: transparent;
    border: none;
}

.chat-input .MuiOutlinedInput-root {
    border-radius: 30px;
    background-color: #F3EEE0;
    padding: 8px 10px; /* Adjust padding */
}

.chat-input .MuiInputBase-input {
    padding: 10px;
    font-size: 16px;
}

.chat-input .MuiIconButton-root {
    color: #faeacc; /* Gray color f32or icons */
}

.chat-input .MuiIconButton-root:hover {
    color: #faeacc; /* Darker gray on hover */
}

.chat-input input[type='file'] {
    display: none;
    
}
