/* src/components/QueueList.css */

.queue-list {
    border-radius: 20px;
    padding: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.list-item {
    border-radius: 12px;
    margin: 10px 0;
    padding: 16px;
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

