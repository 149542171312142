/* src/components/Dashboard.css */

/* General styles for the dashboard */
body, html, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.dashboard-container {
    display: flex;
    height: 100vh;
    background-color: #f0f2f5; /* Background for the entire chat window */
    padding: 20px; /* Space around sections */
    gap: 20px;
}

.main-content {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Floating shadow */
    padding: 15px;
}

.main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Add rounded corners and shadows to the queue and chat window */
.queue-list,
.chat-window {
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    padding: 10px;
}

.drawer-paper {
    width: 300px;
}

.app-bar {
    z-index: 1400 !important;
}

.active-chats-list {
    width: 300px;
    border-right: 1px solid #ccc;
    overflow-y: auto;
}

.no-chat-selected {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-item {
    border-radius: 8px;
    margin: 8px;
}

.list-item.selected {
    background-color: rgba(25, 118, 210, 0.1);
}

.list-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.list-item-avatar {
    margin-right: 16px;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
}

.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
}

.message-bubble {
    padding: 8px 12px;
    border-radius: 8px;
    max-width: 60%;
    box-shadow: 1;
    margin-bottom: 8px;
}

.message-bubble.sent {
    background-color: #ea191c;
    align-self: flex-end;
}

.message-bubble.received {
    background-color: #FFFFFF;
    align-self: flex-start;
}

.message-bubble img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

.message-input {
    display: flex;
    gap: 8px;
    margin-top: 16px;
}

.availability-toggle {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.availability-toggle .MuiTypography-root {
    margin-right: 8px;
}
